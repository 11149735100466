<template>
	<div class="app-container">
		<el-header class="titles">题目类型管理</el-header>
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="searchForm" size="small" label-width="100px">
					<el-form-item label="类型：">
						<el-input v-model="searchForm.name" placeholder="请输入类型"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="append()">新增</el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button type="primary" size="medium" icon="el-icon-s-grid" circle slot="reference"></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1;getList(page)"></el-button>
		</el-row>
		<el-card>
			<el-table :data="tableData" :stripe="true" border v-loading="listLoading" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :label="item.label" min-width="110"></el-table-column>
				<!-- <el-table-column prop="defaultQuestionNum" label="默认题目数量" min-width="110"></el-table-column>
				<el-table-column prop="defaultScore" label="默认分值" min-width="110"></el-table-column>
				<el-table-column prop="defaultSort" label="默认考试顺序" min-width="110"></el-table-column> -->
				<el-table-column prop="createdTxStamp" label="更新日期" min-width="140"></el-table-column>
				<el-table-column label="操作" min-width="100">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-edit" @click="editTable(scope.$index, scope.row)">修改</el-button>
						<!-- <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteTable(scope.$index, scope.row)">删除</el-button> -->
					</template>
				 </el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
		  <el-form :model="form" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
		    <el-form-item label="类型：" prop="type">
		      <el-select style="display: block;" v-model="form.type" placeholder="请选择类型">
		        <el-option v-for="item in typeArr" :key="item" :label="item" :value="item"></el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item label="默认题目数量：" prop="num">
		      <el-input v-model="form.num" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.num = $event.target.value"></el-input>
		    </el-form-item>
		    <el-form-item label="默认分值：" prop="score">
		      <el-input v-model="form.score" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.score = $event.target.value"></el-input>
		    </el-form-item>
		    <el-form-item label="默认考试顺序：" prop="order">
		      <el-input v-model="form.order" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.order = $event.target.value"></el-input>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogConfirm('ruleForm')">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogTitle: '修改数据',
				page: 1,
				searchForm: {
					name: ''
				},//条件查询form
				tableData: [],//表格
				multipleSelection: [],//表格选中数组
				total: 0,//分页总条数
				dialogFormVisible: false,//修改、新增弹窗
				formLabelWidth: '120px',//修改、新增弹窗-label宽度
				typeArr: ["判断题", "单选题", "多选题"],//修改、新增弹窗-类型选择数组
				form: {
					type: '',
					num: '',
					score: '',
					order: ''
				},//修改、新增弹窗-表单内容
				rules: {
					type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
					num: [{ required: true, message: '请输入默认题目数量', trigger: 'blur' }],
					score: [{ required: true, message: '请输入默认分值', trigger: 'blur' }],
					order: [{ required: true, message: '请输入默认考试顺序', trigger: 'blur' }]
				},
				visible: false,
				checkArr: [{label: "类型", val: "name", dis: true}, {label: "默认题目数量", val: "defaultQuestionNum", dis: true}, {label: "默认分值", val: "defaultScore", dis: true}, {label: "默认考试顺序", val: "defaultSort", dis: true}],
				checkList: ["name", "defaultQuestionNum", "defaultScore", "defaultSort"],
				// checkList: [{label: "类型1", val: "name"}, {label: "默认题目数量", val: "defaultQuestionNum"}, {label: "默认分值", val: "defaultScore"}, {label: "默认考试顺序", val: "defaultSort"}]
			}
		},
		components: {
			
		},
		created(){
			this.getList(1);
		},
		methods:{
			getList(page, val) {
				this.listLoading = true;
				val = val ? val : "";
				let data = {
					entity: 'FaExamQuestionsType',
					page: page,
					pageSize: 10,
					filter: '[{"col":"name","type":"like","val":"' + val + '"}]',
					sidx: 'defaultSort',
					sord: 'asc'
				}
				this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', data, this, res => {
					this.tableData = res.rows;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList(val);
			},
			// 修改
			editTable(index, row) {
				this.dialogFormVisible = true;
				this.dialogTitle = "修改数据";
				let data = {
					entity: 'FaExamQuestionsType',
					id: row.id
				}
				this.$comjs.ajax.getAjax('/jqGrid/commonFindDetailById', data, this, res => {
					this.form = {
						id: row.id,
						type: res.data.name,
						num: res.data.defaultQuestionNum,
						score: res.data.defaultScore,
						order: res.data.defaultSort
					}
				});
			},
			// 删除
			deleteTable(index, row) {
				let data = {
					entity: 'FaExamQuestionsType',
					id: row.id
				}
				this.$comjs.ajax.postAjax('/jqGrid/delete', data, this, res => {
					this.tableData.splice(index, 1);
				});
			},
			// 搜索
			searchTable() {
				let val = this.searchForm.name;
				this.page = 1;
				this.getList(this.page, val);
			},
			// 新增
			append() {
				this.dialogFormVisible = true;
				this.dialogTitle = "新增数据";
				this.form = {
					id: "",
					type: "",
					num: "",
					score: "",
					order: ""
				}
			},
			// 修改/新增-确定
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							id: this.form.id ? this.form.id : "",
							name: this.form.type,
							questionNum: this.form.num,
							score: this.form.score,
							sort: this.form.order
						}
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$comjs.ajax.postAjax('/fa/questionsType/save', data, this, res => {
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
								this.$message({
									message: '保存成功！',
									type: 'success',
									duration: 1000
								});
								this.getList(this.page, this.searchForm.name);
							});
						}).catch(() => {
							
						});
					}
				});
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.elCard {
		margin-top: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
</style>

